import React from "react";
import "./App.scss";
import Main from "./containers/Main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactUs from "./pages/ContactUs";
import TermAndConditions from "./pages/TermAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Refund from "./pages/Refund";


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Main />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="terms-conditions" element={<TermAndConditions />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="refund" element={<Refund />} />
          </Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
