import React, { useContext } from "react";
import "./Skills.scss";
import Button from "../../components/button/Button";
import { illustration, skillsSection } from "../../portfolio";
import { Fade } from "react-reveal";
import codingPerson from "../../assets/lottie/codingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import meeting from "../../assets/lottie/meeting.json";
import ReactJsTyping from 'reactjs-typing-effect';
import StyleContext from "../../contexts/StyleContext";

export default function Skills() {
  const { isDark } = useContext(StyleContext);
  if (!skillsSection.display) {
    return null;
  }
  return (
    <div className={isDark ? "dark-mode main" : "main"} id="skills">
      <div className="skills-main-div">
        <Fade left duration={1000}>
          <div className="skills-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={meeting} />
            ) : (
              <img
                alt="Man Working"
                src={require("../../assets/images/developerActivity.svg")}
              ></img>
            )}
          </div>
        </Fade>
        <Fade right duration={1000}>
          <div className="skills-text-div">

            <p
              className={
                isDark
                  ? "dark-mode subTitle skills-text-subtitle"
                  : "subTitle skills-text-subtitle"
              }
            >
              We understand that workspace is a crucial part of your life.
            </p>
            <p
              className={
                isDark
                  ? "dark-mode subTitle skills-text-subtitle"
                  : "subTitle skills-text-subtitle"
              }
            >
              However, We feel that paying rent for it is too much.
            </p>
            <div>
              <p
                className={
                  isDark
                    ? "dark-mode subTitle skills-text"
                    : "subTitle skills-text"
                }
              >
                We are looking for
              </p>
              <h1
                className={isDark ? "dark-mode skills-heading" : "skills-heading"}
              >
                <ReactJsTyping StringList={["Businessmen", "Freelancers", "Professionals", "Employees"]} speed={500} />
              </h1>
              <p className={
                isDark
                  ? "dark-mode subTitle skills-text"
                  : "subTitle skills-text"
              }>
                who can use workspace that is vacant.

              </p>

            </div>
            <div className="button-greeting-div">
              <Button text="Apply Now" href="#contact" />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}