import React from "react";

export default function ContactUs() {
  return (
    <div>
      <h1>ContactUs</h1>
      <p>
        Last updated on 22-12-2023 11:30:33 You may contact us using the
        information below: Merchant Legal entity name: SOS HOSPITALITY PRIVATE
        LIMITED Registered Address: B-3, New Parth Duplex, B/h Rajnagar Club,
        Parimal 4 Rasta, Ahmedabad, Gujarat, PIN: 380013 Operational Address:
        B-3, New Parth Duplex, B/h Rajnagar Club, Parimal 4 Rasta, Ahmedabad,
        Gujarat, PIN: 380013 Telephone No: 9974982455 E-Mail ID:
        development.startofstories@gmail.com
      </p>
    </div>
  );
}
