import React, { useContext } from "react";
import "./Skills.scss";
import Button from "../../components/button/Button";
import { illustration, skillsSection } from "../../portfolio";
import { Fade } from "react-reveal";
import codingPerson from "../../assets/lottie/codingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import ReactJsTyping from 'reactjs-typing-effect';
import StyleContext from "../../contexts/StyleContext";

export default function OneMoreSkill() {
    const { isDark } = useContext(StyleContext);
    if (!skillsSection.display) {
        return null;
    }
    return (
        <div className={isDark ? "dark-mode main" : "main"} id="skills">
            <div className="skills-main-div">
                <Fade left duration={1000}>
                    <div className="skills-text-div">
                        <p
                            className={
                                isDark
                                    ? "dark-mode subTitle skills-text-subtitle"
                                    : "subTitle skills-text-subtitle"
                            }
                        >
                            There is only one Term is applicable
                        </p>
                        <h1
                            className={isDark ? "dark-mode skills-heading" : "skills-heading"}
                        >
                            No Rent Please.
                        </h1>
                        <div>
                            <br />
                            <p
                                className={
                                    isDark
                                        ? "dark-mode subTitle skills-text"
                                        : "subTitle skills-text"
                                }
                            >
                                Use our workspaces across your city for Free and help us.
                            </p>
                            <br />
                            <p
                                className={
                                    isDark
                                        ? "dark-mode subTitle skills-text"
                                        : "subTitle skills-text"
                                }
                            >
                                Only for competent uses.
                            </p>
                        </div>
                        <div className="button-greeting-div">
                            <Button text="Apply Now" href="#contact" />
                        </div>
                    </div>
                </Fade>
                <Fade right duration={1000}>
                    <div className="skills-image-div">
                        {illustration.animated ? (
                            <DisplayLottie animationData={codingPerson} />
                        ) : (
                            <img
                                alt="Man Working"
                                src={require("../../assets/images/developerActivity.svg")}
                            ></img>
                        )}
                    </div>
                </Fade>
            </div>
        </div>
    );
}